<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        :data="users"
        :multiple="selectMultiple"
        @search="search"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <!-- View/Duplicates -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ viewLabel }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('view', null)">
                        <span>All</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('view', 'dup_post_code')">
                        <span
                            >Duplicates - Post Code & Address 1 with Partner not
                            set</span
                        >
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="$emit('view', 'no_partner_account')"
                    >
                        <span
                            >Has Partner but no partner account
                            linked/created</span
                        >
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('view', 'no_will')">
                        <span>No will created</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="$emit('view', 'no_will_but_100_progress')"
                    >
                        <span>No will but 100% progress</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="$emit('view', 'will_but_not_paid')"
                    >
                        <span>Will but not paid</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('view', 'broken_accounts')">
                        <span>Broken Accounts</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
            <!-- Actions -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="toggleSelect">
                        <span>{{
                            selectMultiple ? 'Single Select' : 'Multiple Select'
                        }}</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-if="selectMultiple"
                        :disabled="selected.length !== 2"
                        @click="linkAsPartners"
                        divider
                    >
                        <span>Link as Partners</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Details</vs-th>
            <vs-th>Access</vs-th>
            <vs-th>Will Progress</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="user" :key="index" v-for="(user, index) in data">
                    <vs-td v-if="user.details">
                        <a
                            href="#"
                            class="underline"
                            @click="
                                $router.push({
                                    name: 'user',
                                    params: { id: user.id },
                                })
                            "
                            >{{ user.details.full_name }}</a
                        >
                    </vs-td>
                    <vs-td v-else>Profile not completed</vs-td>

                    <vs-td>
                        {{ user.email }}
                    </vs-td>

                    <vs-td>
                        <div v-if="user.details" class="text-xs italic">
                            {{ user.details.address_line_1 }},
                            {{ user.details.post_code }}
                            <p>
                                <span>{{ user.details.marital_status }}</span>
                                <span v-if="user.details.partner_full_name">
                                    to
                                    {{ user.details.partner_full_name }}</span
                                >
                            </p>
                            <p
                                v-if="user.details.partner"
                                @click="
                                    $router.push({
                                        name: 'user',
                                        params: {
                                            id: user.details.partner.user_id,
                                        },
                                    })
                                "
                            >
                                Partner account -
                                <span class="underline">{{
                                    user.details.partner.full_name
                                }}</span>
                            </p>
                        </div>
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            v-if="user.last_login"
                            color="black"
                            title="Last Login"
                            :text="formatDateAndTime(user.last_login)"
                            position="bottom"
                        >
                            <p class="text-xs italic">
                                <span>{{
                                    timeDifference(user.last_login)
                                }}</span>
                                <span> - Last Login</span>
                            </p>
                        </vx-tooltip>

                        <vx-tooltip
                            color="black"
                            title="Date Joined"
                            :text="formatDateAndTime(user.date_joined)"
                            position="bottom"
                        >
                            <p class="text-xs italic">
                                <span>{{
                                    timeDifference(user.date_joined)
                                }}</span>
                                <span> - Joined</span>
                            </p>
                        </vx-tooltip>
                    </vs-td>
                    <vs-td>
                        <feather-icon
                            v-if="
                                user.subscription_details &&
                                user.subscription_details.active
                            "
                            icon="AwardIcon"
                        ></feather-icon>
                        <feather-icon
                            v-if="Number(user.number_of_wills) >= 1"
                            icon="FileTextIcon"
                            :badge="
                                Number(user.number_of_wills) > 1
                                    ? Number(user.number_of_wills)
                                    : null
                            "
                        />
                        <vs-progress
                            v-if="
                                user.progress &&
                                user.progress.percentage_complete &&
                                user.number_of_wills === 0
                            "
                            :height="7"
                            :color="progressColour(user.progress)"
                            :percent="user.progress.percentage_complete"
                        ></vs-progress>
                        <div v-if="user.coupon" class="text-xs italic">
                            <span v-if="user.coupon.broker"
                                >{{ user.coupon.broker.company_name }}
                                <br /> </span
                            >{{ user.coupon.code }}
                        </div>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'UsersAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        users() {
            return this.data.data
        },
        viewLabel() {
            if (this.view === 'dup_post_code')
                return 'Duplicates - Post Code & Address 1 with Partner not set'
            else if (this.view === 'no_partner_account')
                return 'Has Partner but no partner account linked/created'
            else if (this.view === 'no_will') return 'No will created'
            else if (this.view === 'no_will_but_100_progress')
                return 'No will but 100% progress'
            else if (this.view === 'will_but_not_paid')
                return 'Will created but not paid for'
            else if (this.view == 'broken_accounts') return 'Broken Accounts'
            return 'All'
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        progressColour(value) {
            if (value.percentage_complete === 100) return 'success'
            return 'secondary'
        },
        linkAsPartners() {
            if (!this.selected.length === 2) return
            var data = []
            for (var i = 0; i < this.selected.length; i++) {
                data.push(this.selected[i].id)
            }
            this.$emit('linkAsPartners', data)
            this.selected = []
        },
    },
}
</script>
